import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef } from "react";
import { andThen, propOr, compose, T, isEmpty, max, min, map, last } from "ramda";
import { useDebouncedCallback } from "use-debounce";
import { track } from "src/main_app/mixpanel";
import { useUserPermissions, checkPermission } from "src/main_app/actions";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid,
   Tooltip, ResponsiveContainer, Legend, ReferenceLine, ReferenceArea} from 'recharts';
import ReactMapGL, {
  Popup,
  WebMercatorViewport,
  FlyToInterpolator,
  NavigationControl,
} from "react-map-gl";
import moment from "moment";
import { getMapboxAccessKey } from "../../utils/config-utility";
import { useBooleanStage } from "src/main_app/utils/react_utils";
import heatmapLayer from "./map-style";
import heatMapStyle from "./heat-map-style";
import { Source, Layer } from "react-map-gl";
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { addDays, convertDate, firstDateOfMonthsAgo, isNotNil, randomColor, isNilOrEmpty} from "src/main_app/utils/common";
import AsyncSelect from '@bhunjadi/react-select-v3/async';
import { waterZoneList,
  deviceAlerts,
  usageAlerts,
  // highUsageCustomers,
  dataLineChart,
  dataLineChart2,
  // deviceStats,
  listDays } from "src/main_app/utils/constant-data";
import Paginate from "./paginate";
import { getDataNoneRevenueWaterByZone, getDataResponsiveMeter, getDeviceAlertPointMap, getDeviceStats, getHighUsageCustomer, getUsageDataHeatMap } from "src/main_app/services/apis";
import MapClusters from "src/main_app/components/map/map-clusters";
import MarkerInfo from "src/main_app/components/map/map_markers_info/marker-info";
import { use } from "react";
import { layerNames } from "src/main_app/components/map/layers/layer-config";

const mapboxAccessKey = getMapboxAccessKey();

const DashBoardPage = (props) => {
  useEffect(() => {
    track("dashboard page");
  }, []);
  const totalPages = 7;

  const timeoutRef = useRef(null);
  const mapRef = useRef(null);
  const mapDeviceRef = useRef(null);
  const tenantConfig = useTenantConfig();
  const [loading, setIsLoading, setIsLoaded] = useBooleanStage(true);
  const [waterZoneAggregateMulti, setWaterZoneAggregateMulti] = useState([]);
  const [waterZoneNonRevenueMulti, setWaterZoneNonRevenueMulti] = useState([]);
  const [highUsageCustomers, setHighUsageCustomers] = useState([]);
  const [deviceStats, setDeviceStats] = useState({
    negative88: 0,
    negative98: 0,
    negative111: 0,
    negative112: 0,
    total: 0,
  });
  const [activeUsageIndex, setActiveUsageIndex] = useState(0);
  const [activeDeviceIndex, setActiveDeviceIndex] = useState(0);

  const [showUsagePicker, setShowUsagePicker] = useState(false);
  const [showWaterUsagePicker, setShowWaterUsagePicker] = useState(false);
  const [showPercentMeterPicker, setShowPercentMeterPicker] = useState(false);
  const [showPercentDeliveryPicker, setShowPercentDeliveryPicker] = useState(false);
  const [isOpenWaterUsage, setIsOpenWaterUsage] = useState(false);
  const [isOpenUsageCustomer, setIsOpenUsageCustomer] = useState(false);
  const [isOpenPercentMeter, setIsOpenPercentMeter] = useState(false);
  const [isOpenPercentDelivery, setIsOpenPercentDelivery] = useState(false);
  const [dayOfWaterUsage, setDayOfWaterUsage] = useState('Last 7 days');
  const [dayOfUsageCustomer, setDayOfUsageCustomer] = useState('Last 7 days');
  const [dayOfPercentMeter, setDayOfPercentMeter] = useState('Last 7 days');
  const [dayOfPercentDelivery, setDayOfPercentDelivery] = useState('Last 7 days');

  const [dataPercentMeter, setDataPercentMeter] = useState([]);
  const [dataPercentDelivery, setDataPercentDelivery] = useState([]);

  const [isOpenDevice, setIsOpenDevice] = useState(false);
  const [dayOfDevice, setDayOfDevice] = useState(7);

  const [usagePercentMeter, setUsagePercentMeter] = useState(0);

  //Data for Non-revenue water
  const ticksRef = useRef([]);
  const [dataNonRevenue, setDataNonRevenue] = useState(null);
  const [meterNames, setMeterNames] = useState([]);

  const [namePopupInfo, setNamePopupInfo] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [waterUsageHeatMap, setWaterUsageHeatMap] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [deviceAlertPoints, setDeviceAlertPoints] = useState([]);
  const [readyForRenderClusterLayers, setReadyForRenderClusterLayers] = useState(false);

  const [layersVisible, setLayersVisible] = useState({
    [layerNames.customers]: true,
  });

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "dashboard-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      // alert("You don't have permission to view this page");
      // document.location.href = "/map";
    }
  }, [canViewThisPage]);

  // const allMarkersData = useMemo(
  //   () => [deviceAlertPoints],
  //   [deviceAlertPoints]
  // );

  // const allMarkersData = useMemo(
  //   () => [...wasteWaterMarkersData, ...damsMarkersData],
  //   [wasteWaterMarkersData, damsMarkersData]
  // );
  
  const getBoundViewPort = (data) => {
    if (!data || data.length === 0) return null;
  
    let topLeftLat = -90;
    let topLeftLong = 180;
    let bottomRightLat = 90;
    let bottomRightLong = -180;
  
    data.forEach((marker) => {
      topLeftLat = max(topLeftLat, marker.latitude);
      topLeftLong = min(topLeftLong, marker.longitude);
      bottomRightLat = min(bottomRightLat, marker.latitude);
      bottomRightLong = max(bottomRightLong, marker.longitude);
    });
  
    return [
      [topLeftLong, topLeftLat],   // Corrected for top-left (min longitude, max latitude)
      [bottomRightLong, bottomRightLat]  // Corrected for bottom-right (max longitude, min latitude)
    ];
  };

  const mapDataSums = async (date) => {
    const ticks = []
    const allMeterNames = [];
    const { data: { data } } = await getDataNoneRevenueWaterByZone(date)
  
    let arrDataNonRevenue = map((item) => {
      const { date, ...metersData } = item;
  
      if (isEmpty(metersData)) return { date };
  
      const utcTime = moment(date).utcOffset(0, true);
  
      const utcUnix = utcTime.unix();
  
      if (
        isEmpty(ticks) ||
        utcUnix - last(ticks) >= 86400
      ) {
        ticks.push(utcUnix);
      }
  
      const combinedData = {
        date: utcUnix,
        displayDate: utcTime.format("MMM DD"),
      };
  
      for (const meterName in metersData) {
        if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
        allMeterNames.push(meterName);
        const sumUsage = metersData[meterName];
        combinedData[meterName] = sumUsage
          ? Math.round(sumUsage * 10) / 10
          : null;
      }
  
      ticksRef.current  = ticks
  
      return combinedData;
    }, data);
  
    const setOfMeterNames = [...new Set(allMeterNames)];
    arrDataNonRevenue = map((item) => {
      for (const meterName of setOfMeterNames) {
        if (isNotNil(item[meterName])) continue;
        item[meterName] = 0;
      }
  
      return item;
    }, arrDataNonRevenue);
  
    return {setOfMeterNames, arrDataNonRevenue}
  }

  const toggleDropdownUsageCustomer = () => {
    setIsOpenUsageCustomer(!isOpenUsageCustomer);
  };

  const toggleDropdownWaterUsage = () => {
    setIsOpenWaterUsage(!isOpenWaterUsage);
  };

  const toggleDropdownDevice = () => {
    setIsOpenDevice(!isOpenDevice);
  };

  const toggleDropdownPercentMeter = () => {
    setIsOpenPercentMeter(!isOpenPercentMeter);
  };
  
  const toggleDropdownPercentDelivery = () => {
    setIsOpenPercentDelivery(!isOpenPercentDelivery);
  };

  const exportUsageDataMap = () => {

  }

  const exportDeviceDataMap = () => {
    
  }
  
  const normalViewport = {
    mapStyle: "mapbox://styles/loweblue0510/ckkqoydb526lt17s1s5ql84of",
    pitch: undefined,
  };
  const defaultPosition = {
    longitude: 151.20511015442966,
    latitude: -33.84472358322997,
    zoom: 7,
  };

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "55vh",
    ...defaultPosition,
    ...(normalViewport || {}),
  });

  const [deviceViewport, setDeviceViewport] = useState({
    width: "100%",
    height: "55vh",
    ...defaultPosition,
    ...(normalViewport || {}),
  });

  const yLabel = {
    value: "Water Usage (mL)",
    angle: -90,
    position: "top",
    dy: 110,
    dx: -22,
  }

  const yLabelSmallChart = {
    value: "1.1 mL",
    position: "top",
    dy: 50,
    dx: -15,
  }

  const handleUsageClick = (index, title) => {
    setActiveUsageIndex(index, );
    queryWaterUsageHeatMap(title);
  };

  const handleDeviceClick = (index) => {
    setActiveDeviceIndex(index);
    queryDeviceAlertPointMap(title)
  };

  const queryWaterUsageHeatMap = async (typeUsage) => {
    const type = typeUsage ? typeUsage : "Leaks alerts";
    const {
      data: { data },
    } = await getUsageDataHeatMap(type, null);

    if (data.length === 0) {
      props.dataLoaded && props.dataLoaded();
    } else {
      setWaterUsageHeatMap({
        ...waterUsageHeatMap,
        features: data.map((customer) => ({
          type: "Feature",
          properties: {
            wupd: customer.water_usages_per_day,
          },
          geometry: {
            type: "Point",
            coordinates: [customer.longitude, customer.latitude],
          },
        })),
      });
    }
  };


  const queryDeviceAlertPointMap = async (typeUsage) => {
    const type = typeUsage ? typeUsage : "device type";
    const {
      data: { data },
    } = await getDeviceAlertPointMap(type, null);

    if (data.length === 0) {
      props.dataLoaded && props.dataLoaded();
    } else {
      setDeviceAlertPoints(data);
      setTimeout(() => {
        onFitBounds(data);
      }, 3000);
    }
  };

  const CustomLegend = () => (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px 0" }}>
        <span
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#d8e6d8",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: "5px"
          }}
        ></span>
        <span className="mr-3">Target percentage</span>
        {/* <span
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#dca728",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: "5px"
          }}
        ></span>
        <span className="mr-3">Zone 1</span>

        <span
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#04a2b3",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: "5px"
          }}
        ></span>
        <span className="mr-3">Zone 2</span> */}
    </div>
  );

const loadData = async () => {
  const { data: { data } } = await getHighUsageCustomer(dayOfUsageCustomer)
  const dataDevice = await getDeviceStats(dayOfDevice)
  const devicePercentMeter = await getDataResponsiveMeter()
  const dataPercentMeter = await getDataResponsiveMeter(dayOfPercentMeter)
  const dataPercentDelivery = await getDataResponsiveMeter(dayOfPercentDelivery)
  const dataRealTime = await mapDataSums(dayOfPercentDelivery);
  setDataNonRevenue(dataRealTime['arrDataNonRevenue']);
  setMeterNames(dataRealTime['setOfMeterNames']);
  setHighUsageCustomers(data)
  setDeviceStats(dataDevice['data'])
  setDataPercentMeter(dataPercentMeter['data']['data'])
  setDataPercentDelivery(dataPercentDelivery['data']['data'])
  setUsagePercentMeter(devicePercentMeter['data']['data'])
}

const onChangeWaterUsage = async (day) => {
  // const { data: { data } } = await getHighUsageCustomer(day, {
  //   from: convertDate(dateRangeWaterUsage[0]),
  //   to: convertDate(dateRangeWaterUsage[1]),
  // })
  if (day === "Custom date range") {
    setShowWaterUsagePicker(true);
  } else {
    setShowWaterUsagePicker(false);
  }
  setDayOfWaterUsage(day)
}

const onChangeHighUsageCustomer = async (day) => {
  const { data: { data } } = await getHighUsageCustomer(day, {
    from: convertDate(dateRangeWaterUsage[0]),
    to: convertDate(dateRangeWaterUsage[1]),
  })
  if (day === "Custom date range") {
    setShowUsagePicker(true);
  } else {
    setShowUsagePicker(false);
  }
  setHighUsageCustomers(data)
  setDayOfUsageCustomer(day)
}

const onChangePercentMeter = async (day) => {
  if (day === "Custom date range") {
    setShowPercentMeterPicker(true);
  } else {
    setShowPercentMeterPicker(false);
    const dataPercentMeter = await getDataResponsiveMeter(day)
    setDataPercentMeter(dataPercentMeter['data']['data'])
  }
  setDayOfPercentMeter(day)
}


const onChangePercentDelivery = async (day) => {
  if (day === "Custom date range") {
    setShowPercentDeliveryPicker(true);
  } else {
    setShowPercentDeliveryPicker(false);
    const dataPercentDelivery = await getDataResponsiveMeter(day)
    setDataPercentDelivery(dataPercentDelivery['data']['data'])
  }
  setDayOfPercentDelivery(day)
}

const onChangeDeviceStats = async (day) => {
  const dataDevice = await getDeviceStats(day)
  setDeviceStats(dataDevice['data'])
  setDayOfDevice(day)
}

const formatDataML = (value) => {
  return (Math.round(value)).toLocaleString() + ' kL';
}

const onChangeNonRevenue = (page) => {
}

const onApplyHightUsageCustomer = async (event, picker) => {
    event.stopPropagation();
    const fromDate = picker.startDate.utcOffset(0, true).toDate()
    const toDate = picker.endDate.utcOffset(0, true).toDate()

    const { data: { data } } = await getHighUsageCustomer(dayOfUsageCustomer, {
      from: convertDate(fromDate),
      to: convertDate(toDate),
    })
    setHighUsageCustomers(data)
}

const onApplyWaterUsageChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()
}

const onApplyPercentMeterChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()

  const { data: { data } } = await getDataResponsiveMeter(dayOfPercentMeter, {
    from: convertDate(fromDate),
    to: convertDate(toDate),
  })
  
  setDataPercentMeter(data)
 
}


const onApplyPercentDeliveryChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()

  const { data: { data } } = await getDataResponsiveMeter(dayOfPercentDelivery, {
    from: convertDate(fromDate),
    to: convertDate(toDate),
  })
  
  setDataPercentDelivery(data)
 
}

// Sample Data for Line Chart (Volume)
const dataLine = [
  { name: "Nov 15", percentage: 1, volume: 400 },
  { name: "Nov 16", percentage: 5, volume: 300 },
  { name: "Nov 17", percentage: 2, volume: 200 },
  { name: "Nov 18", percentage: 8, volume: 278 },
  { name: "Nov 19", percentage: 10, volume: 189 },
  { name: "Nov 20", percentage: 7, volume: 557 },
  { name: "Nov 21", percentage: 14, volume: 189 },
  { name: "Nov 22", percentage: 3, volume: 445 },
];

// Target range
const targetRangeStart = 8;
const targetRangeEnd = 12;

  useEffect(() => {
    loadData();
    queryWaterUsageHeatMap(null);
    queryDeviceAlertPointMap(null);

  }, []);

  const onApplyWaterUsage = useCallback(
    (event, picker) => {
      const fromDate = picker.startDate.utcOffset(0, true).toDate()
      const toDate = picker.endDate.utcOffset(0, true).toDate()
      // getUsageData &&
      //   getUsageData(
      //     picker.startDate.utcOffset(0, true).toDate(),
      //     picker.endDate.utcOffset(0, true).toDate()
      //   );
    },
    // [getUsageData]
  );

  const dateRangeWaterUsage = useMemo(() => {
    const today = new Date();
    const toDate = addDays(today, -1);
    const fromDate = addDays(today, -8);
    return [fromDate, toDate];
  }, []);

  const handleChildClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenUsageCustomer(false);
  };

  const handleChildWaterUsageClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenWaterUsage(false);
  };

  const handleChildPercentMeterClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenPercentMeter(false);
  };

  const handleChildPercentDeliveryClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenPercentMeter(false);
  };

  ///////////////
  const handleAggregateMultiChange = (option) => {
    if (option != null) {
      const listWaterZone = option.map(item => (item.value));
      setWaterZoneAggregateMulti(listWaterZone)
    } else {
      setWaterZoneAggregateMulti([])
    }
  }

  const promiseOptions = async (inputValue) => {
    return waterZoneList;
  }
  
  const handleNonRevenueMultiChange = (option) => {
    if (option != null) {
      const listWaterZone = option.map(item => (item.value));
      setWaterZoneNonRevenueMulti(listWaterZone)
    } else {
      setWaterZoneNonRevenueMulti([])
    }
  }

  const promiseNonRevenueOptions = async (inputValue) => {
    return waterZoneList;
  }

  useLayoutEffect(() => {
    import("./style.scss");
  }, []);

  const clearInfoWindow = () => {
    if (isNilOrEmpty(popupInfo) || layersVisible[popupInfo.type]) return;

    setPopupInfo(null);
  };

  const onFitBounds = (data) => {
    const boundViewPort = getBoundViewPort(data);
    if (!boundViewPort) return;
  
    const { longitude, latitude, zoom } = new WebMercatorViewport({
      ...deviceViewport,
      width: window.innerWidth,  // Ensure width is properly set
      height:
        window.innerHeight -
        document.querySelector(".app__header").clientHeight  // Ensure height is properly set
    }).fitBounds(boundViewPort, {
      padding: 20  // Adjust padding as needed
    });
  
    setDeviceViewport({
      ...deviceViewport,
      height:
        window.innerHeight -
        document.querySelector(".app__header").clientHeight,
      width: window.innerWidth,
      longitude,
      latitude,
      zoom: zoom - 1,  // Optional: Adjust zoom as needed
      transitionInterpolator: new FlyToInterpolator({
        speed: 3,
      }),
      transitionDuration: "auto",
    });

    setDeviceAlertPoints(data);
  };


  useLayoutEffect(() => {
    console.log("tenantConfig", tenantConfig);
    if (!tenantConfig.defaultPosition) return;
    
    setViewport({ ...viewport, ...tenantConfig.defaultPosition });
    setDeviceViewport({ ...deviceViewport, ...tenantConfig.defaultPosition });
  }, [tenantConfig]);


  const clearNamePopupInfo = () => setNamePopupInfo(null);

  const onMarkerClick = compose(clearNamePopupInfo, setPopupInfo);

  useLayoutEffect(() => {
    clearInfoWindow();
  }, [
    layersVisible[layerNames.customers],
  ]);

  useLayoutEffect(() => {
    if (!mapDeviceRef.current) return;
    if (timeoutRef.current !== null) return;
    timeoutRef.current = setInterval(() => {
      try {
        const bounds = mapDeviceRef.current.getMap().getBounds().toArray().flat();
        clearInterval(timeoutRef.current);
        setTimeout(() => {
          setReadyForRenderClusterLayers(bounds);
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    }, 666);
    return () => {
      clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, [mapDeviceRef.current, setReadyForRenderClusterLayers]);

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content dashboard">
      {/* <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="mb-0">Dashboard</h2>
        </div>
      </div> */}

      <div className="container-fluid content mt-5" >
        <div className="section-usage">
          {/* <h2 className="mb-0">Usage</h2> */}
          <hr></hr>
           {/* design 2 */}
          <div className="row mt-4 alert-left-box">
            <div className="col-md-4 pd-r-0 bg-alert-map usage-alert-tab">
              <div className="bg-white recent">
                <div className="heading">
                  <h3 className="title">Usage Alerts</h3>
                </div>
                <div className="tickets">
                  {usageAlerts.map((item, index) => (
                      <div 
                        key={index}  
                        className={`row cursor mt-3 ${activeUsageIndex == index ? 'active-item-full-w' : 'alert'}`}
                        onClick={() => handleUsageClick(index, item.title)}
                      >
                        <div className="col-md-2 ticket-total-alert min-h-alert">
                          <p>{item.value}</p>
                        </div>
                        <div className={`col-md-10 pr-0 min-h-alert title-alert ${activeUsageIndex == index ? 'active-d' : ''}`} >
                          {item.title}
                        </div>
                      
                      </div>
                  ))}
                </div>
              </div>

            </div>
            <div className="col-md-8 pd-l-0">
              <div className="map">
                <div className="map-layer">
                  <div className="heading">
                    <h3 className="title text-right pr-5">
                      <span onClick={() => exportUsageDataMap()}>Export <i className="fas fa-download mr-4" /></span>
                      <span>View all <i className="far fa-angle-right" /></span>
                    </h3>
                  </div>
                  <div className="map-active">
                    <ReactMapGL
                        ref={mapRef}
                        {...viewport}
                        mapStyle={normalViewport.mapStyle}
                        mapboxApiAccessToken={mapboxAccessKey}
                        onViewportChange={setViewport}
                        onTransitionStart={setIsLoading}
                        onTransitionEnd={setIsLoaded}
                        attributionControl={false}
                        maxZoom={15}
                      >
                      {waterUsageHeatMap.features.length > 0 && (
                        <Source type="geojson" data={waterUsageHeatMap}>
                          <Layer {...heatMapStyle} />
                        </Source>
                      )}
                      {/* {earthquakes && (
                        <Source type="geojson" data={earthquakes}>
                          <Layer {...heatmapLayer} />
                        </Source>
                      )} */}
                    </ReactMapGL>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start design Usage graph */}
          <div className="row mt-4">
            <div className="col-md-4">
              <div className="recent">
                <div className={`heading-h-usage-customer bg-white ${showUsagePicker ? 'no-p' : ''}`}>
                  <h3 className="title">
                    <div className="header-container">
                      <span className="left-text">High Usage Customers</span>
                      <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownUsageCustomer()} > {dayOfUsageCustomer}{showUsagePicker ? ':' : ''} 
                      {showUsagePicker && (
                        <span className="dropdown" onClick={handleChildClick}>
                          <DateRangePicker
                            setting={{
                              startDate: dateRangeWaterUsage[0],
                              endDate: dateRangeWaterUsage[1],
                            }}
                            dateDisplayFormat="MMM DD"
                            onApply={onApplyHightUsageCustomer}
                          >
                            <input type="text" className="form-control input-date-range"/>
                          </DateRangePicker>
                        </span>
                      )}

                      <i className="fas fa-caret-down pl-1" />
                      {isOpenUsageCustomer && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangeHighUsageCustomer(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span>
                      
                    </div>
                  </h3>
                </div>
                <div className="bg-gray">
                  {highUsageCustomers.map((item, index) => (
                    <div key={index} className="item-customer">
                      <div className="header-container">
                        <span className="left-text">{item.name}</span>
                        <span className="right-text">{formatDataML(Number(item.usage))}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>

            <div className="col-md-8">
              {/* start line chart 1 */}
              <div className="line-chart">
                <h3 className={`title heading-h-usage-customer bg-white ${showWaterUsagePicker ? 'no-p' : ''}`} >
                  <div className="header-container">
                    <span className="left-text">Non-Revenue Water by Zone</span>
                    <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownWaterUsage()} > {dayOfWaterUsage}{showWaterUsagePicker ? ':' : ''} 
                      {showWaterUsagePicker && (
                        <span className="dropdown" onClick={handleChildWaterUsageClick}>
                          <DateRangePicker
                            setting={{
                              startDate: dateRangeWaterUsage[0],
                              endDate: dateRangeWaterUsage[1],
                            }}
                            dateDisplayFormat="MMM DD"
                            onApply={onApplyWaterUsageChart}
                          >
                            <input type="text" className="form-control input-date-range"/>
                          </DateRangePicker>
                        </span>
                      )}

                      <i className="fas fa-caret-down pl-1" />
                      {isOpenWaterUsage && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangeWaterUsage(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                      )}
                    </span>
                  </div>
                </h3>
                  <div className="p-4 bg-gray">
                    <CustomLegend />
                    <ResponsiveContainer width="100%" height={190}>
                      {/* <LineChart data={dataLine}> */}
                      <LineChart data={dataNonRevenue}>
                        <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                        <XAxis dataKey="displayDate" />
                        <YAxis
                          yAxisId="left"
                          orientation="left"
                          label={{ value: "kL",  position: "insideLeft" }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          label={{ value: "%", position: "insideRight" }}
                          domain={[0, 16]}
                        />
                        <Tooltip />
                        <Legend iconType={"circle"} />

                         {meterNames.map((meterName, index) => (
                          <Line key={index + ""} yAxisId="left" type="monotone" dataKey={meterName} stroke={index === 0 ? "#04a2b3" : randomColor(index)} strokeWidth={2} unit={" kL"} dot={false} />
                          // <Line key={index + ""} yAxisId="left" type="monotone" dataKey={meterName} stroke={index === 0 ? "#04a2b3" : randomColor(index)} strokeWidth={2} unit={" ML"} dot={false} />
                        ))} 

                        {/* <Line yAxisId="right" type="monotone" dataKey="percentage" stroke="#dca728" strokeWidth={2} dot={false} /> */}
                        {/* <Line yAxisId="left" type="monotone" dataKey="volume" stroke="#04a2b3" strokeWidth={2} dot={false} /> */}

                        {/* Horizontal Target Range as a ReferenceArea */}
                        <ReferenceArea
                          yAxisId="right"
                          y1={targetRangeStart}
                          y2={targetRangeEnd}
                          fill="#008a007d"
                          fillOpacity={0.2}
                          // label={{
                          //   value: "Target Range",
                          //   position: "insideTop",
                          //   fill: "black"
                          // }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>

              </div>
              {/* end line chart 1 */}
            </div>
          </div>
          {/* END design Usage graph */}
        </div>

        <div className="section-devices mt-5">
          {/* <h2 className="mb-0">Devices</h2> */}
          <hr></hr>
          {/* design 3 */}
          <div className="row mt-4 alert-left-box">
            <div className="col-md-4 pr-0 bg-alert-map">
              <div className="bg-white recent">
                <div className="heading">
                  <h3 className="title">Device Alerts</h3>
                </div>
                <div className="tickets">
                  {deviceAlerts.map((item, index) => (
                    <div 
                        key={index}  
                        className={`row cursor mt-3 ${activeDeviceIndex == index ? 'active-item-full-w' : 'alert'}`}
                        onClick={() => handleDeviceClick(index, item.title)}
                      >
                        <div className="col-md-2 ticket-total-alert min-h-alert">
                          <p>{item.value}</p>
                        </div>
                        <div className={`col-md-10 pr-0 min-h-alert title-alert ${activeDeviceIndex == index ? 'active-d' : ''}`} >
                          {item.title}
                        </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            <div className="col-md-8 pl-0">
              <div className="map">
                <div className="map-layer">
                  <div className="heading">
                    {/* <h3 className="title">Devices | Reading exceptions | Signal strength</h3> */}
                    <h3 className="title text-right pr-5">
                      <span onClick={() => exportDeviceDataMap()}>Export <i className="fas fa-download mr-4" /></span>
                      <span>View all <i className="far fa-angle-right" /></span>
                    </h3>
                  </div>
                  <div className="map-active">
                    <ReactMapGL
                        ref={mapDeviceRef}
                        {...deviceViewport}
                        mapStyle={normalViewport.mapStyle}
                        mapboxApiAccessToken={mapboxAccessKey}
                        onViewportChange={setDeviceViewport}
                        onTransitionStart={setIsLoading}
                        onTransitionEnd={setIsLoaded}
                        attributionControl={false}
                        maxZoom={15}
                      >
                        {readyForRenderClusterLayers ? (
                          <>
                            {/* {deviceAlertPoints.length > 0 && ( */}
                              <MapClusters
                              bounds={readyForRenderClusterLayers}
                              data={deviceAlertPoints}
                              onMouseIn={setNamePopupInfo}
                              onMouseOut={clearNamePopupInfo}
                              onClick={onMarkerClick}
                              viewport={deviceViewport}
                              setViewport={setDeviceViewport}
                              zoom={deviceViewport.zoom}
                              type="customers"
                              visible={true}
                            />
                            {/* )} */}
                          </>
                        ) : null}

                        {popupInfo && (
                          <Popup
                            tipSize={5}
                            anchor="bottom"
                            longitude={popupInfo.longitude}
                            latitude={popupInfo.latitude}
                            onClose={setPopupInfo}
                            offsetLeft={0}
                            offsetTop={-10}
                            closeButton={true}
                            closeOnClick={false}
                          >
                            <MarkerInfo info={popupInfo} type="info-window" />
                          </Popup>
                        )}
                        {namePopupInfo && (
                          <Popup
                            tipSize={5}
                            anchor="bottom"
                            longitude={namePopupInfo.longitude}
                            latitude={namePopupInfo.latitude}
                            onClose={setNamePopupInfo}
                            offsetLeft={0}
                            offsetTop={-10}
                            closeButton={false}
                            closeOnClick={true}
                          >
                            <MarkerInfo info={namePopupInfo} type="tooltip" />
                          </Popup>
                        )}
                    </ReactMapGL>
                  </div>
                </div>
              </div>
            </div>
          </div>    

        </div>
       
                    
        {/* design 4 */}
        <div className="row mt-4">
          {/* <div className={`header-container ${isActive ? 'active' : ''}`}> */}
          <div className="col-md-4">
            <div className="recent">
              <div className="heading-h-usage-customer bg-white">
                <h3 className="title">
                  <div className="header-container">
                    <span className="left-text">Device Stats</span>
                    {/* <span className="right-text dropdown" onClick={() => toggleDropdownDevice()} >Last {dayOfDevice} days <i className="fas fa-caret-down" />
                      {isOpenDevice && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangeDeviceStats(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span> */}
                  </div>
                </h3>
              </div>

              <div className="bg-gray">
                <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Total meters</span>
                      <span className="right-text">{deviceStats.total}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Responsive meters</span>
                      <span className="right-text">{usagePercentMeter[0] ? `${usagePercentMeter[0]['Percent']} %`  : '0'}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Data delivery</span>
                      <span className="right-text">{usagePercentMeter[0] ? `${usagePercentMeter[0]['Percent']} %`  : '0'}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Signal strength {'>'} -88 RSRP</span>
                      <span className="right-text">{deviceStats.negative88}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Signal strength between -88 and –98 RSRP</span>
                      <span className="right-text">{deviceStats.negative98}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Signal strength between –98 and -111 RSRP</span>
                      <span className="right-text">{deviceStats.negative111}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Signal strength {'<='} -112 (ECL Mode 2)</span>
                      <span className="right-text">{deviceStats.negative112}</span>
                      {/* <span className="right-text">{deviceStats.negative98 + deviceStats.negative112*2}</span> */}
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            {/* start line chart 1 */}
            <div className="line-chart">
                <h3 className={`title heading-h-usage-customer bg-white ${showPercentMeterPicker ? 'no-p' : ''}`} >
                  <div className="header-container">
                    <span className="left-text">Percentage responsive meters</span>
                    <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownPercentMeter()} > {dayOfPercentMeter}{showPercentMeterPicker ? ':' : ''} 
                      {showPercentMeterPicker && (
                        <span className="dropdown" onClick={handleChildPercentMeterClick}>
                          <DateRangePicker
                            setting={{
                              startDate: dateRangeWaterUsage[0],
                              endDate: dateRangeWaterUsage[1],
                            }}
                            dateDisplayFormat="MMM DD"
                            onApply={onApplyPercentMeterChart}
                          >
                            <input type="text" className="form-control input-date-range"/>
                          </DateRangePicker>
                        </span>
                      )}

                      <i className="fas fa-caret-down pl-1" />
                      {isOpenPercentMeter && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangePercentMeter(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                      )}
                    </span>
                  </div>
                </h3>
                {/* <h3 className="title">
                  <div className="header-container">
                    <span className="left-text">Percentage responsive meters</span>
                    <span className="right-text dropdown" onClick={() => toggleDropdownPercentMeter()} >Last {dayOfPercentMeter} days <i className="fas fa-caret-down" />
                      {isOpenPercentMeter && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => setDayOfPercentMeter(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span>
                  </div>
                </h3> */}
                {/* <h3 className="title">Percentage responsive meters</h3> */}
              </div>
              <div className="p-4 bg-gray">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={dataPercentMeter}>
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                    <XAxis dataKey="date" />
                    <YAxis stroke="#e9ebf1" 
                    // label={yLabel} 
                    domain={[0, 100]} 
                    />
                    <Tooltip />
                    {/* <Legend  iconType={"circle"} /> */}
                    <Line type="monotone" dataKey="Percent" stroke="#04a2b3" strokeWidth={2} dot={false}/>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            {/* </div> */}
            {/* end line chart 1 */}

            {/* start line chart 2 */}
            <div className="line-chart mt-4">
              <h3 className={`title heading-h-usage-customer bg-white ${showPercentDeliveryPicker ? 'no-p' : ''}`} >
                <div className="header-container">
                  <span className="left-text">Percentage data delivery</span>
                  <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownPercentDelivery()} > {dayOfPercentDelivery}{showPercentDeliveryPicker ? ':' : ''} 
                    {showPercentDeliveryPicker && (
                      <span className="dropdown" onClick={handleChildPercentDeliveryClick}>
                        <DateRangePicker
                          setting={{
                            startDate: dateRangeWaterUsage[0],
                            endDate: dateRangeWaterUsage[1],
                          }}
                          dateDisplayFormat="MMM DD"
                          onApply={onApplyPercentDeliveryChart}
                        >
                          <input type="text" className="form-control input-date-range"/>
                        </DateRangePicker>
                      </span>
                    )}

                    <i className="fas fa-caret-down pl-1" />
                    {isOpenPercentDelivery && (
                        <ul className="dropdown-list">
                          {listDays.map((item, index) => (
                            <li key={index} className="dropdown-item text-center" onClick={() => onChangePercentDelivery(item)} >
                              {item}
                            </li>
                          ))}
                        </ul>
                    )}
                  </span>
                </div>
              </h3>
              {/* <div className="heading">
                <h3 className="title">
                  <div className="header-container">
                    <span className="left-text">Percentage data delivery</span>
                    <span className="right-text dropdown" onClick={() => toggleDropdownPercentDelivery()} >Last {dayOfPercentDelivery} days <i className="fas fa-caret-down" />
                      {isOpenPercentDelivery && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => setDayOfPercentDelivery(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span>
                  </div>
                </h3>
              </div> */}
              {/* <div className="p-4 col-md-5">
                <label>Water zone</label>
                <AsyncSelect placeholder={<div>Empty to all zone</div>}
                    isMulti
                    cacheOptions
                    defaultOptions={waterZoneList}
                    loadOptions={promiseNonRevenueOptions}
                    onChange={handleNonRevenueMultiChange}
                />
              </div> */}
              <div className="p-4 bg-gray">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={dataPercentDelivery}>
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                    <XAxis dataKey="date" />
                    <YAxis stroke="#e9ebf1" 
                    // label={yLabel} 
                    domain={[0, 100]}
                    />
                    <Tooltip />
                    {/* <Legend  iconType={"circle"} /> */}
                    <Line type="monotone" dataKey="Percent" stroke="#04a2b3" strokeWidth={2} dot={false}/>
                  </LineChart>
                </ResponsiveContainer>


                {/* <div className="d-flex justify-content-lg-start pagination-box">
                  <Paginate totalPages={totalPages} onPageChange={onChangeNonRevenue} />
                </div> */}
              </div>
            </div>
            {/* end line chart 2 */}
          </div>

        </div>
      </div>
    </div>
  );

};

export default DashBoardPage;
